*
{
    margin: 0;
    padding: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

html {
    font-size: 62.5%;
} 

html,
body
{
    overflow: hidden;
    font-family: 'Work Sans', sans-serif;
    cursor: none;
    background: #121230;
}

a {
    text-decoration: none;
    color: #FFF;
}

strong {
    font-weight: bold;
}

.hide {
    display: none !important;
}

.logo {
    background: url(../static/images/eye.svg);
    background-repeat: no-repeat;
    background-size: 59px;
    width: 60px;
    height: 32px;
    display: block;
    transition: transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    margin: 0 auto 20px auto;
}

.logo:hover {
    transform: scale(1.2);
}

/* Perspective card */
.card-wrapper {
    z-index: 2;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    /* pointer-events: none; */
}

.card {
    width: 120vw;
    height: 100vh;
    font-size: 2rem;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform-style: preserve-3d;
    text-align: center;
    transform: perspective(2000px);
    transition: transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.card-text {
    width: 450px;
    transform: translate(25vw, -60px) rotate3d(0, 1, 0, 0deg);
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    will-change: auto;
    max-width: 40vw;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.08);
    box-shadow: 0 0 40px rgba(251,127,159,0.1);
    padding: 40px 20px;
    border-radius: 20px;
    transition: transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.card-text.rotate {
    transform: translate(25vw, -60px) rotate3d(0, 1, 0, 360deg);
}

.border {
    position: relative;
    display: inline-block;
}

.border::after {
    content: '';
    position: absolute;
    bottom: 2px;
    left: -2px;
    width: calc(100% + 4px);
    height: 5px;
    background: linear-gradient(60deg,#17e1fd 10%,#ff77e1 50%,#fff177);
    z-index: -1;
    box-shadow: 0 0px 10px #121230;
}

.btn {
    padding: 10px;
    text-decoration: none;
    color: #FFF;
    margin-top: 30px;
    display: inline-block;
    position: relative;
    background-color: #2a2a45;
    width: 184px;
    border-radius: 4px;
    transition: all 0.3s ease;
    text-shadow: 0px 1px 1px rgba(0,0,0,0.3);
}

.btn::before {
    transition: all 0.3s ease;
    content: '';
    box-shadow: 0 0 rgba(0,0,0,0.17);
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 4px;
    transform: translate(-7px, -7px);
}

.btn:hover::before {
    box-shadow: 10px 10px rgba(0,0,0,0.17);

}

.btn:after {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    width: 210px;
    height: 50px;
    z-index: -1;
    background: linear-gradient(60deg,#17e1fd 10%,#ff77e1 50%,#fff177);
    border-radius: 4px;
}

.footer {
    position: fixed;
    width: 300vw;
    height: 60px;
    background: #FFF;
    background-image: url(../static/images/banner.svg);
    background-repeat: repeat-x;
    background-size: 500px 60px;
    background-position: right;
    bottom: 0;
    left: -50%;
    z-index: 1;
    transition: transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform-style: preserve-3d;
    /* transform: translate(-50%, 0); */
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}


@media screen and (max-width: 768px) {
    .card {
        justify-content: flex-end;
    }
    .card-text {
        width: 100%;
        max-width: 80vw;
        transform: translate(0, -60px) rotate3d(0, 1, 0, 0deg);
        padding: 20px 10px;
        font-size: 1.8rem;
    }
    .rotate {
        transform: translate(0, -60px) rotate3d(0, 1, 0, 360deg) !important;
    }
    .footer {
        display: none;
    }
}